import { Link, useStaticQuery, graphql } from "gatsby"
import React, { useState, useEffect } from "react"
import WhitelogoImg from "../images/logo-white.svg"
import LogoImg from "../images/logo.svg"
import LinkButton from "../components/link-button"
import "./header.css"

function Header({ isRootPath }) {
  const [isExpanded, toggleExpansion] = useState(false)
  const [isProductExpanded, toggleProductExpansion] = useState(false)
  const [onTop, setOnTop] = useState(true)
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  function onScroll() {
    const offset =
      window.pageYOffset ||
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop

    if (offset >= 100 && !onTop) return false
    if (offset >= 100 && onTop) {
      setOnTop(false)
      return false
    }
    setOnTop(true)
  }

  useEffect(() => {
    document.addEventListener("scroll", onScroll)
    return () => document.removeEventListener("scroll", onScroll)
  })

  return (
    <nav
      className={`${
        !onTop | isExpanded && "bg-white shadow-lg"
      } sticky top-0 w-full z-50 px-4 py-2 lg:p-5`}
    >
      <div className="flex flex-wrap items-center justify-between">
        <div className={`flex items-center flex-shrink-0 mr-7`}>
          <Link to={`/`} className="hover:opacity-80">
            <img
              className={`
                ${isRootPath && onTop && !isExpanded ? "block" : "hidden"}
                 w-40 lg:w-auto
              `}
              src={WhitelogoImg}
              alt={data.site.siteMetadata.title}
            />
            <img
              className={`
                ${isRootPath && onTop && !isExpanded ? "hidden" : "block"}
                 w-40 lg:w-auto
              `}
              src={LogoImg}
              alt={data.site.siteMetadata.title}
            />
          </Link>
        </div>
        <div className="block lg:hidden -mr-3">
          <button
            onClick={() => {
              toggleExpansion(!isExpanded)
              toggleProductExpansion(false)
            }}
            className={`flex items-center px-3 py-2  ${
              isRootPath && onTop && !isExpanded
                ? "text-white"
                : "text-blue-950"
            } ${isExpanded && `rotate-180`}`}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.293 7.293L9.99997 11.586L5.70697 7.293L4.29297 8.707L9.99997 14.414L15.707 8.707L14.293 7.293Z"
                fill={isRootPath && onTop && !isExpanded ? "white" : "#0A293B"}
              />
            </svg>
          </button>
        </div>
        <div
          className={`${
            isExpanded ? `block` : `hidden`
          } w-full block lg:flex lg:items-center lg:w-auto mb-1 py-4 lg:py-0`}
        >
          <div
            className={`text-sm lg:flex-grow lg:flex lg:flex-gap-6 lg:items-stretch ${
              isRootPath && onTop && !isExpanded
                ? "text-white"
                : "text-blue-950"
            }`}
          >
            <Link to={`/about-cdp/`} className="block py-2 text-xs text-center lg:flex lg:items-center hover:opacity-80 lg:py-0 font-medium">
                          CDPの基礎知識
                        </Link>
            <div className={`product lg:flex lg:items-center relative`}>
              <button
                className={`flex py-2 text-xs items-center  justify-center lg:justify-items-start gap-2 lg:py-0 font-medium hover:opacity-80 w-full lg:w-auto`}
                onClick={() => toggleProductExpansion(!isProductExpanded)}
              >
                製品
                <div className={`${isProductExpanded && `rotate-180`}`}>
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.14601 3.64511L4.99951 5.79161L2.85301 3.64511L2.14601 4.35211L4.99951 7.20561L7.85301 4.35211L7.14601 3.64511Z"
                      fill={
                        isRootPath && onTop && !isExpanded ? "white" : "#0A293B"
                      }
                    />
                  </svg>
                </div>
              </button>
              <div
                className={`product-menu ${
                  isProductExpanded ? `block` : `hidden`
                }`}
              >
                <Link to={`/function/`} className="product-menu-item">
                  機能
                </Link>
                <Link to={`/tools/`} className="product-menu-item">
                  連携ツール
                </Link>
                <Link to={`/security/`} className="product-menu-item">
                  セキュリティ
                </Link>
                <Link to={`/jetjunction-ga4/`} className="product-menu-item">
                  JetJunction
                </Link>
              </div>
            </div>
            <Link to={`/support/`} className="block py-2 text-xs text-center lg:flex lg:items-center hover:opacity-80 lg:py-0 font-medium">
              サポート
            </Link>
            <a
              href="https://www.ever-rise.co.jp/dx-blog/cases-integral-core"
              className="block py-2 text-xs text-center lg:flex lg:items-center hover:opacity-80 lg:py-0 font-medium"
              target="_blank"
            >
              導入企業
            </a>
            <Link to={`/seminar/?filter=accepting`} className="block py-2 text-xs text-center lg:flex lg:items-center hover:opacity-80 lg:py-0 font-medium">
              セミナー・動画
            </Link>
            <Link to={`/resource/`} className="block py-2 text-xs text-center lg:flex lg:items-center hover:opacity-80 lg:py-0 font-medium">
              資料
            </Link>
            <a
              href="https://www.ever-rise.co.jp/dx-blog/tag/cdp/"
              className="block py-2 text-xs text-center lg:flex lg:items-center hover:opacity-80 lg:py-0 font-medium"
              target="_blank"
            >
              ブログ
            </a>
            <Link to={`/demo-request/`} className="block py-2 text-xs text-center lg:flex lg:items-center hover:opacity-80 lg:py-0 font-medium">
              デモリクエスト
            </Link>
            <div className="text-sm pt-3 lg:pt-0">
              <div className=" lg:flex-grow flex flex-gap-2 items-center justify-center">
              <LinkButton
                text="製品資料ダウンロード"
                url="/resource/integral-core/"
                type="secondary"
                size="s"
              />
              <LinkButton
                text="導入相談"
                url="/contact"
                type="primary"
                size="s"
              />
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Header
