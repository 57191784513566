import * as React from "react"

import Header from "./header"
import Footer from "./footer"
import GlobalHeader from "./global-header"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]', {
    offset: 84,
  })
}

const Layout = ({ location, children, hasCta }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <GlobalHeader/>
      <Header isRootPath={isRootPath} />
      <main className={"-mt-12 lg:-mt-20"}>{children}</main>
      <Footer hasCta={hasCta} />
    </div>
  )
}

export default Layout
