import { Link, useStaticQuery, graphql } from "gatsby"
import React from "react"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"
import WhitelogoImg from "../images/logo-white.svg"
import Container from "../components/container"
import LinkButton from "../components/link-button"

const Footer = ({ hasCta }) => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <footer>
      {hasCta && (
        <section className="bg-gradient-to-r from-gradationAccent-from to-gradationAccent-to pt-10 pb-10 lg:pt-20 lg:pb-20">
          <Container>
            <h1 className="text-white text-lg lg:text-4xl font-bold text-center mb-4 lg:mb-8">
              まずはお気軽にご相談ください
            </h1>
            <div className="flex justify-center flex-gap-3">
              <LinkButton text="導入相談" url="/contact/" type="primary" />
              <LinkButton
                text="製品資料ダウンロード"
                url="/resource/integral-core/"
                type="secondary"
              />
            </div>
          </Container>
        </section>
      )}
      <nav className="pt-6 pb-6 lg:pt-9 lg:pb-6 bg-blue-950 text-white">
        <Container>
          <div className="lg:flex lg:justify-between items-start">
            <div className="lg:w-1/5 mb-6 lg:mb-0  lg:text-left">
              <Link
                to={`/`}
                className="hover:opacity-70 mb-4 lg:mb-12 inline-block"
              >
                <img src={WhitelogoImg} alt={data.site.siteMetadata.title} />
              </Link>
              <div className="flex-gap-3 w-full flex lg:block mb-8 lg:mb-2">
                <Link
                  to={`/contact/`}
                  className="text-white border border-white rounded text-xs font-bold w-28 text-center block p-2 hover:bg-white hover:text-blue-950 lg:mb-3"
                >
                  導入相談
                </Link>
                <Link
                  to={`/demo-request/`}
                  className="text-white border border-white rounded text-xs font-bold w-28 text-center block p-2 hover:bg-white hover:text-blue-950"
                >
                  デモリクエスト
                </Link>
              </div>
            </div>
            <div className="flex flex-wrap lg:flex-gap-6 flex-gap-4 lg:w-3/5 lg:grid-cols-3 grid-cols-2 text-xs text-blue-50">
            <div className="flex-grow">
                <div className="mb-7">
                  <h2 className="font-medium pb-1 mb-2">CDPとは</h2>
                  <Link to={`/about-cdp/`} className="hover:opacity-80 block mb-2">
                  CDPの基礎知識
                  </Link>
                  <a
                    href="https://www.ever-rise.co.jp/dx-blog/cdp-dmp"
                    className="hover:opacity-80 block mb-2"
                    target="_blank"
                  >
                    CDPとDMPの違い
                  </a>
                  <a
                    href="https://www.ever-rise.co.jp/dx-blog/cdp-crm"
                    className="hover:opacity-80 block mb-2"
                    target="_blank"
                  >
                    CDPとCRMの違い
                  </a>
                  <a
                    href="https://www.ever-rise.co.jp/dx-blog/cdp-dwh"
                    className="hover:opacity-80 block mb-2"
                    target="_blank"
                  >
                    CDPとDWHの違い
                  </a>
                  <a
                    href="https://www.ever-rise.co.jp/integral-core/resource/cdp-ma/"
                    className="hover:opacity-80 block mb-2"
                    target="_blank"
                  >
                    CDPとMAの違い
                  </a>
                </div>
              </div>
              <div className="flex-grow">
                <div className="mb-7">
                  <h2 className="font-medium pb-1 mb-2">製品</h2>
                  <Link
                    to={`/function/`}
                    className="hover:opacity-80 block mb-2"
                  >
                    機能
                  </Link>
                  <Link to={`/tools/`} className="hover:opacity-80 block mb-2">
                    連携ツール
                  </Link>
                  <Link to={`/security/`} className="hover:opacity-80 block mb-2">
                    セキュリティ
                  </Link>
                  <Link to={`/support/`} className="hover:opacity-80 block mb-2">
                    サポート
                  </Link>
                  <Link to={`/jetjunction-ga4/`} className="hover:opacity-80 block">
                    JetJunction
                  </Link>
                </div>
              </div>
              <div className="flex-grow">
                <div className="mb-7">
                  <h2 className="font-medium pb-1 mb-2">リソース</h2>
                  <Link
                    to={`/seminar/`}
                    className="hover:opacity-80 block mb-2"
                  >
                    セミナー・動画
                  </Link>
                  <Link
                    to={`/resource/`}
                    className="hover:opacity-80 block mb-2"
                  >
                    資料
                  </Link>
                  <a
                    href={`https://www.ever-rise.co.jp/dx-blog/tag/cdp`}
                    className="hover:opacity-80 block mb-2"
                  >
                    ブログ
                  </a>
                  <a
                    href={`https://www.ever-rise.co.jp/news/tag/integral-core/`}
                    className="hover:opacity-80 block mb-2"
                  >
                    ニュース
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:flex lg:justify-between text-blue-50 text-xs items-end pt-10 lg:pt-24">
            <div className="mb-6 lg:mb-0">
              <div className="flex flex-gap-4">
                <StaticImage
                  layout="fixed"
                  width={112}
                  src="../images/sponsor-apac-real.png"
                  alt="Sponsor APAC Real"
                />
                <StaticImage
                  layout="fixed"
                  width={80}
                  src="../images/isms.jpg"
                  alt="ISMS"
                />
              </div>
            </div>

            <div className="flex flex-gap-6">
              <a
                href={`https://www.ever-rise.co.jp`}
                className="hover:opacity-80"
              >
                株式会社EVERRISE
              </a>
              <a
                href={`https://www.ever-rise.co.jp/privacy`}
                className="hover:opacity-80"
              >
                個人情報保護方針
              </a>
            </div>
          </div>
        </Container>
      </nav>
    </footer>
  )
}

Footer.defaultProps = {
  hasCta: true,
}

Footer.propTypes = {
  hasCta: PropTypes.bool.isRequired,
}

export default Footer
